<template>
	<div class="body">
		<Tabs :value="action" @on-click="switchover">
			<TabPane label="基本信息" name="first">
				<div class="lmd-title">设备基本信息</div>
				<!-- <div class="lmd-item"><div class="lmd-item-title">设备名称</div><div>{{ infoData.device_name }}</div></div> -->
				<div class="lmd-item"><div class="lmd-item-title">设备id(序列号)</div><div>{{ infoData.imei }}</div></div>
				<div class="lmd-item"><div class="lmd-item-title">设备验证码</div><div>{{ infoData.validate_code }}</div></div>
				<div class="lmd-item"><div class="lmd-item-title">绑定状态</div><div><Tag :color="infoData.is_binding ? 'success' : 'default'">{{ infoData.is_binding ? '已绑定' : '无绑定' }}</Tag></div></div>
				<!-- <div class="lmd-item"><div class="lmd-item-title">封面</div><div class="thumb-div"><img :src="infoData.thumb" style="width: 100%; height: 100%;" /></div></div> -->
				<div class="lmd-item"><div class="lmd-item-title">创建时间</div><div>{{ infoData.create_date }}</div></div>
			</TabPane>
			<TabPane label="实时预览" name="second">
				<div class="lmd-preview-top">
					<div class="lmdpt-left">
						<div class="lmdpt-left-video">
							<!-- <span v-if="!infoData.status">离线</span> -->
							<img v-if="infoData.thumb" v-show="!play" :src="infoData.thumb.indexOf('http') != -1 ? infoData.thumb : (infoData.thumb.indexOf('/uploads') != -1 ? 'https://adm.api.jk125.com'+infoData.thumb : 'https://'+infoData.thumb)" alt="封面" style="width: 100%; height: 100%;">
							<div v-show="play" id="video-container" style="line-height: 0px;"></div>
						</div>
						<div class="lmdpt-left-btm">
							<div>状态：在线</div>
							<Tooltip transfer placement="right">
								<div slot="content">
									<p>获取当前监控画面</p>
									<!-- <p v-else >由于设备离线,无法执行启动拉流操作</p> -->
								</div>
								<Button type="success" size="small" @click="start_play">{{ play ? '关闭拉流' : '启动拉流'}}</Button>
							</Tooltip>
						</div>
					</div>
					<div class="lmdpt-right">
						<div class="lmdpt-right-top lmdpt-right-data">
							<div class="lmdcc-direction">
								<div class="lmd-control-title">方向调节<Tooltip content="用于球机远程云台控制" placement="right"><i class="iconfont icon-wenhao"></i></Tooltip></div>
								<div class="lmdcc-items">
									<div class="lmdcc-items-li" @click="cloudControlStart(4)" ><Icon class="ri-lg" style="transform:rotate(-45deg);" type="md-arrow-up" /></div>
									<div class="lmdcc-items-li" @click="cloudControlStart(0)" ><Icon class="ri-lg" type="md-arrow-up" /></div>
									<div class="lmdcc-items-li" @click="cloudControlStart(6)" ><Icon class="ri-lg" style="transform:rotate(45deg);" type="md-arrow-up" /></div>
									<div class="lmdcc-items-li" @click="cloudControlStart(2)"><Icon class="ri-lg" type="md-arrow-back" /></div>
									<div class="lmdcc-items-li lmdcc-items-center"></div>
									<div class="lmdcc-items-li" @click="cloudControlStart(3)" ><Icon class="ri-lg" type="md-arrow-forward" /></div>
									<div class="lmdcc-items-li" @click="cloudControlStart(5)" ><Icon class="ri-lg" style="transform:rotate(45deg);" type="md-arrow-down" /></div>
									<div class="lmdcc-items-li" @click="cloudControlStart(1)" ><Icon class="ri-lg" type="md-arrow-down" /></div>
									<div class="lmdcc-items-li" @click="cloudControlStart(7)" ><Icon class="ri-lg" style="transform:rotate(-45deg);" type="md-arrow-down" /></div>
								</div>
							</div>
							<div class="lmdcc-shot">
								<div class="lmd-control-title">镜头控制</div>
								<div class="lmdct-items">
									<div class="lmdct-items-op" @click="cloudControlStart(10)"><Icon class="ri-lg" type="md-add" /></div>
									<div class="lmdct-items-txt">变焦</div>
									<div class="lmdct-items-op" @click="cloudControlStart(11)"><Icon class="ri-lg" type="md-remove" /></div>
								</div>
								<div class="lmdct-items">
									<div class="lmdct-items-op" @click="cloudControlStart(8)"><Icon class="ri-lg" type="md-add" /></div>
									<div class="lmdct-items-txt">变倍</div>
									<div class="lmdct-items-op" @click="cloudControlStart(9)"><Icon class="ri-lg" type="md-remove" /></div>
								</div>
								<div class="lmdct-items">
									<div class="lmdct-items-op" @click="alertErr('设备不支持')"><Icon class="ri-lg" type="md-add" /></div>
									<div class="lmdct-items-txt">光圈</div>
									<div class="lmdct-items-op" @click="alertErr('设备不支持')"><Icon class="ri-lg" type="md-remove" /></div>
								</div>
							</div>
							<!-- <div class="lmdcc-step">
								<div class="lmd-control-title">步长</div>
								<Slider v-model="value1" vertical></Slider>
							</div> -->
						</div>
						<div class="lmdpt-right-top">
							<div class="lmdpt-right-title">流信息</div>
							<!-- <div class="lmdpt-right-top-item">
								<div class="rti-title">设备名称</div>
								<div class="rti-val">{{ infoData.device_name }}</div>
							</div> -->
							<div class="lmdpt-right-top-item">
								<div class="rti-title">设备id</div>
								<div class="rti-val">{{ infoData.imei }}</div>
							</div>
							<div class="lmdpt-right-top-item">
								<div class="rti-title">创建时间</div>
								<div class="rti-val">{{ infoData.create_date }}</div>
							</div>
							<div class="lmdpt-right-top-item">
								<div class="rti-title">播放流地址</div>
								<div class="rti-val">{{ ezopenUrl }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="lmd-control">
					<div class="lmd-control-con">
						<div class="lmdcc-position">
							<div class="lmd-control-title">
								预置位<Tooltip content="记忆当前画面位置" placement="top"><i class="iconfont icon-wenhao"></i></Tooltip>
								<Button type="success" size="small" style="margin-left: 10px;" @click="edit_preset_show()">新增预置位</Button>
								<Button type="success" size="small" style="margin-left: 10px;" @click="get_preset_list()">获取预置位</Button>
							</div>
							<div class="lmdp-table">
								<Table :loading="loading1" :columns="columns1" :data="data1" border no-data-text="<p style='line-height: 275px; text-align: center;'>暂无数据</p>">
									<template slot="operate" slot-scope="{row,index}">
										<Button @click="edit_preset_show(row.id,index)">编辑</Button>
										<Button @click="dele_preset_list(row.id)">删除</Button>
									</template>
								</Table>
							</div>
						</div>
						<div class="lmdcc-cruise">
							<div class="lmd-control-title">
								巡航组<Tooltip content="按照设置的预置位进行巡航" placement="top"><i class="iconfont icon-wenhao"></i></Tooltip>
								<Button type="success" size="small" style="margin-left: 10px;" @click="alertErr('设备不支持该操作')">添加巡航组</Button>
							</div>
							<div style="margin-top: 12px;">
								<Table :loading="loading2" :columns="columns2" :data="data2" border no-data-text="<p style='line-height: 275px; text-align: center;'>暂无数据</p>"></Table>
							</div>
						</div>
					</div>
				</div>
			</TabPane>
			<Button @click="go_back()" size="small" slot="extra">返回</Button>
		</Tabs>
		<Modal v-model="preset_show" title="新增预置位" class-name="monitoring-center-modal" @on-cancel="edit_preset_cancel()">
			<Form :label-width="100" label-colon>
				<FormItem label="预置位名称">
					<Input v-model="presetData.preset_name" placeholder="请输入预置位名称"></Input>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="text" @click="edit_preset_cancel()">取消</Button>
				<Button type="primary" @click="edit_preset_submit()">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import  EZUIKit from 'ezuikit-js';
	
	export default {
		name: 'monitoringInfo',
		data () {
			return {
				imei: 0, // 设备信息id 
				action: 'first', // 标签名称
				infoData: {
					device_id: '',
					validate_code: '',
					device_name: '',
					status: 1,
					thumb: '',
					add_time: ''
				},
				loading1:false , // 预置位表格加载状态
				columns1: [  // 预置位表格表头
					{ title: 'ID', key: 'id', align: 'center' }, 
					{ title: '名称', key: 'name', align: 'center' },
					{ title: '操作', slot: 'operate', align: 'center' }
				],
				data1: [], // 预置位表格数据
				loading2:false ,// 巡航组表格加载状态
				columns2: [ // 巡航组表格表头
					{ title: 'ID', key: 'id' },
					{ title: '名称', key: 'name' },
					{ title: '速度', key: 'speed' },
					{ title: '停留时间', key: 'stay_time' },
					{ title: '操作', slot: 'operate' },
				],
				data2: [],  // 巡航组表格数据
				player: null, // 视频组件
				ezopenUrl: '', // 拉流地址
				accessToken: '', // accessToken
				play: false, // 拉流状态
				action_sum: 0,
				operateStatus: [true,true,true,true,true,true,true,true,true,true,true,true], // 操作类型数组 0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距
				preset_show: false ,// 添加预置位 弹窗
				presetData: {
					id: 0,
					preset_name: ''
				}
			}
		},
		created () {
			this.imei = this.$route.query.imei
			this.action = this.$route.query.action
			this.init()
		},
		methods: {
			init(){
				let _this = this
				let action_type = 0
				if(this.action == 'first'){ action_type = 0 }else{ action_type = 1}
				this.requestApi('/adm/get_monitoring_device_info', { imei: this.imei, type: action_type } ).then((res)=>{
					if(res.status){
						_this.infoData = res.data
						_this.accessToken = res.token
						_this.ezopenUrl = res.live_address
						if(_this.action == 'second' && !_this.action_sum){
							_this.action_sum++ 
							console.group("EZUIKit 组件挂载完毕状态========》");
							_this.player =  new EZUIKit.EZUIKitPlayer({
								autoplay: false,
								id: "video-container",
								accessToken: _this.accessToken,
								url: _this.ezopenUrl,
								template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
								audio: _this.infoData.is_audio, // 是否默认开启声音 0 - 关闭 1 - 开启
								width: 622,
								height: 395
							});
						}
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			//返回上一页
			go_back(){
				this.$router.go(-1);
			},
			// 切换标签页
			switchover(name){
				this.action = name
				this.init()
			},
			// 启动拉流
			start_play(){
				var _this = this;
				if(!this.player) return;
				if(this.play){
					this.player.stop();
					this.play = false;
				}else{
					this.player.play().then(() => {
						// 延时操作 开启或关闭声音
						setTimeout(()=>{
							if(_this.infoData.is_audio == 1){
								_this.player.openSound();
							}else{
								_this.player.closeSound();
							}
						},1000);
					});
					this.play = true;
				}
			},
			// 云台操作
			cloudControlStart(operate_id){
				if(this.operateStatus[operate_id]){
					let _this = this
					this.requestApi('/adm/operate_monitoring_info_start',{ device_id: this.infoData.imei, operate_id: operate_id }).then((res)=>{
						if(res.status){
							_this.operateStatus[operate_id] = false
						}else{
							_this.alertErr(res.msg)
						}
					})
				}else{
					let _this = this
					this.requestApi('/adm/operate_monitoring_info_end',{ device_id: this.infoData.imei, operate_id: operate_id }).then((res)=>{
						if(res.status){
							this.operateStatus[operate_id] = true
						}else{
							_this.alertErr(res.msg)
						}
					})
				}
			},
			// 添加预置位
			edit_preset_show(id = 0,index = 0){
				this.preset_show = true
				if(id){
					this.presetData = JSON.parse(JSON.stringify(this.data1[index]))
				}
			},
			// 获取预置位
			get_preset_list(){
				let _this = this
				this.loading1 = true
				this.requestApi('/adm/get_monitoring_preset',{ device_id: this.infoData.imei }).then((res)=>{
					if(res.status){
						_this.loading1 = false
						_this.data1 = res.data
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			// 提交 添加/编辑预置位
			edit_preset_submit(){
				let _this = this
				this.requestApi('/adm/edit_monitoring_preset', { data: this.presetData, token: this.accessToken, device_id: this.infoData.imei } ).then((res)=>{
					if(res.status){
						_this.edit_preset_cancel()
						_this.get_preset_list()
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			// 删除 预置位
			dele_preset_list(id){
				let _this = this
				this.requestApi('/adm/del_monitoring_preset',{ id: id, token: this.accessToken }).then((res)=>{
					if(res.status){
						_this.get_preset_list()
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			// 清除 当前编辑信息
			edit_preset_cancel(){
				this.preset_show = false
				let data = {
					id: 0,
					preset_name: ''
				}
				this.presetData = data
			}
		}
	}
</script>

<style scoped>
	.body{
		padding: 16px;
		background-color: #fff;
	}
	.lmd-title{
		margin-top: 12px;
		font-size: 16px;
		font-weight: 700;
	}
	.lmd-item{
		display: flex;
		align-items: center;
		height: 44px;
		font-size: 14px;
	}
	.lmd-item-title{
		color: #999;
		width: 200px;
	}
	.thumb-div{
		width: 44px;
		height: 44px;
	}
	.lmd-preview-top{
		display: flex;
		padding: 24px 12px;
	}
	.lmd-preview-top .lmdpt-left{
		width: 622px;
		height: 450px;
		box-shadow: -4px 4px 9px #e4e4e4;
		border-radius: 6px;
	}
	.lmd-preview-top .lmdpt-left .lmdpt-left-video{
		width: 622px;
		background: #000;
		text-align: center;
		color: #fff;
		height: 395px;
		line-height: 350px;
	}
	.lmd-preview-top .lmdpt-left .lmdpt-left-btm{
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 12px;
		font-size: 14px;
	}
	.lmd-preview-top .lmdpt-right{
		margin-left: 24px;
		flex-grow: 1;
	}
	.lmd-preview-top .lmdpt-right-top {
		padding: 12px 24px;
		border-radius: 6px;
		box-shadow: -4px 4px 9px #e4e4e4;
		font-size: 14px;
		box-sizing: border-box;
		overflow: hidden;
		overflow-x: auto;
		margin-top: 25px;
		height: 160px;
	}
	.lmd-preview-top .lmdpt-right-data{
		height: 265px !important;
		display: flex;
		align-items: center;
		margin-top: 0 !important;
	}
	.lmdcc-direction{
		width: 210px;
		padding-left: 12px;
	}
	.lmdcc-shot {
		width: 180px;
		margin-left: 24px;
	}
	.lmd-control-title{
		color: #999;
		font-size: 14px;
	}
	.lmdcc-direction .lmdcc-items{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.lmdcc-direction .lmdcc-items-li {
		width: 36px;
		height: 36px;
		border: 1px solid #e8e8e8;
		margin: 12px 15px 15px 0;
		cursor: pointer;
		text-align: center;
		line-height: 40px;
		font-size: 16px;
	}
	.lmdcc-direction .lmdcc-items-center{
		width: 40px;
		border: none;
		box-shadow: none;
	}
	.lmdcc-shot .lmdct-items{
		width: 100%;
		display: flex;
		align-items: center;
		margin: 12px 15px 28px 0;
	}
	.lmdcc-shot .lmdct-items-op {
		width: 36px;
		height: 36px;
		border: 1px solid #e8e8e8;
		text-align: center;
		line-height: 40px;
		margin: 0 12px;
		cursor: pointer;
		font-size: 16px;
	}
	.lmdcc-shot .lmdct-items-txt {
		font-size: 14px;
		color: #666;
	}
	.ri-lg {
		font-size: 1.3333em;
		line-height: 0.75em;
		vertical-align: -0.0667em;
	}
	.lmd-preview-top .lmdpt-right-title {
		font-size: 14px;
	}
	.lmd-preview-top .lmdpt-right-top-item {
		height: 30px;
		display: flex;
		align-items: center;
	}
	.lmd-preview-top .lmdpt-right-top .rti-title {
		color: #999;
		width: 120px;
	}
	.lmd-preview-top .lmdpt-right-top .rti-val {
		color: #999;
		line-height: 38px;
	}
	.lmd-control-con {
		display: flex;
		justify-content: space-between;
	}
	.lmdcc-position {
		width: 47%;
		margin-left: 24px;
	}
	.lmdcc-cruise {
		width: 47%;
	}
	.lmdcc-position .lmdp-table {
		width: 100%;
		margin-top: 12px;
	}
	.iconfont{
		margin-left: 3px;
		font-size: 14px;
	}
</style>

<style>
	.monitoring-center-modal{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.monitoring-center-modal .ivu-modal{
		top: 0;
	}
</style>